import { ApolloClient, InMemoryCache, gql, HttpLink, ApolloLink } from '@apollo/client'
import { BigNumberish } from 'ethers';

const APIURL = process.env.REACT_APP_LENS_API_URL || 'https://api-mumbai.lens.dev';
const httpLink = new HttpLink({uri: APIURL});
const authLink = new ApolloLink((operation, forward) => {
  const token =  localStorage.getItem('auth_token')
   // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
  headers: {
    'x-access-token': token ? `Bearer ${token}`: '',
  }
}));

  return forward(operation);
})

const config = {
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
}
export let apolloClient= new ApolloClient(config)

export async function getLoginChallenge (address: string) {
  const query = gql`query($request: ChallengeRequest!) {
    challenge(request: $request) {
          text
      }
    }`
  const resp = await apolloClient.query({
    query: query,
    variables: {
      request: {
        address
      }
    }
  })

  console.log('got challenge response: ', resp);
  return resp;
}

export async function authenticateUser (address: string, signature: string) {
 const mutation = gql`mutation($request: SignedAuthChallenge!) { 
    authenticate(request: $request) {
      accessToken
      refreshToken
    }
  }`
  
  const resp = await apolloClient.mutate({
    mutation: mutation,
    variables: {
      request: {
        address,
        signature
      }
    }
  })

  console.log('got auth response ', resp)
  return resp;
}

export async function createPost (params: any) {
  const mutation = gql`
      mutation($request: CreatePublicPostRequest!) { 
        createPostTypedData(request: $request) {
          id
          expiresAt
          typedData {
            types {
              PostWithSig {
                name
                type
              }
            }
          domain {
            name
            chainId
            version
            verifyingContract
          }
          value {
            nonce
            deadline
            profileId
            contentURI
            collectModule
            collectModuleInitData
            referenceModule
            referenceModuleInitData
          }
        }
      }
    }
  `
  const resp = await apolloClient.mutate({
    mutation: mutation,
    variables: {
      request: {...params}
    }
  })

  return resp

}

export async function getLensProfile (params: {profileId?: string, handle?: string}) {
  console.log('api Endpoint ', APIURL);
  const GET_PROFILE = `
    query($request: SingleProfileQueryRequest!) {
      profile(request: $request) {
          handle
          name
          bio
          attributes {
            displayType
            traitType
            key
            value
          }
          followNftAddress
          metadata
          isDefault
          picture {
            ... on NftImage {
              contractAddress
              tokenId
              uri
              verified
            }
            ... on MediaSet {
              original {
                url
                mimeType
              }
            }
            __typename
          }
          handle
          coverPicture {
            ... on NftImage {
              contractAddress
              tokenId
              uri
              verified
            }
            ... on MediaSet {
              original {
                url
                mimeType
              }
            }
            __typename
          }
          ownedBy
          dispatcher {
            address
            canUseRelay
          }
          stats {
            totalFollowers
            totalFollowing
            totalPosts
            totalComments
            totalMirrors
            totalPublications
            totalCollects
          }
          followModule {
            ... on FeeFollowModuleSettings {
              type
              amount {
                asset {
                  symbol
                  name
                  decimals
                  address
                }
                value
              }
              recipient
            }
            ... on ProfileFollowModuleSettings {
              type
            }
            ... on RevertFollowModuleSettings {
              type
            }
          }
      }
    }
  `;

  return apolloClient.query({
    query: gql(GET_PROFILE),
    variables: {
      request: { ...params }
    },
  })
}

export async function createProfileTestnet (params: { handle: string, currency: string, recipient: string}) {
  const CREATE_PROFILE = `
  mutation($request: CreateProfileRequest!) { 
    createProfile(request: $request) {
      ... on RelayerResult {
        txHash
      }
      ... on RelayError {
        reason
      }
            __typename
    }
 }
`
 console.log('params: ', params);
  const resp = await apolloClient.mutate({
    mutation: gql(CREATE_PROFILE),
    variables: {
      request: {
        handle: params.handle,
        profilePictureUri: null,
      }
    },
  })

  return resp
  
}


export async function createSetDispatcherTypedData (params: any) {
  const CREATE_SET_DISPATCHER_TYPED_DATA = `
    mutation($request: SetDispatcherRequest!) { 
      createSetDispatcherTypedData(request: $request) {
        id
        expiresAt
        typedData {
          types {
            SetDispatcherWithSig {
              name
              type
            }
          }
          domain {
            name
            chainId
            version
            verifyingContract
          }
          value {
            nonce
            deadline
            profileId
            dispatcher
          }
        }
      }
  }
`

  return apolloClient.mutate({
    mutation: gql(CREATE_SET_DISPATCHER_TYPED_DATA),
    variables: {
      request: {
        ...params
      }
    },
  })
}

export async function verifyJwt (accessToken: string) {
  const VERIFY = gql`
    query($request: VerifyRequest!) {
      verify(request: $request)
    }
  `

  const resp = await apolloClient.query({
    query: VERIFY,
    variables: {
      request: {
        accessToken
      }
    }
  });

  return resp;

}