import { styled } from '@mui/material/styles';
export const Item = styled("div")(({ theme }) => ({
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  // maxWidth: '85%',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  }
  // color: theme.palette.text.secondary,
}));

export const TerminalCard = (props: Record<string, any>) => {
  return (
  <Item  className="terminal-card">
    <header> {props.cardHeader || "Zk3 v0.0.1"}</header>
    <div>
      {props.children}
    </div>
  </Item>
  );
}