import { useEffect, useRef, useState } from "react";
import { TerminalCard } from "../terminalCard";
import useLensProfile from "../../hooks/useLensProfile";
import { useNetwork, useSigner, useContractRead } from "wagmi";
import Grid from '@mui/material/Unstable_Grid2';
import { Button } from '@mui/material';
import useZkIdentity from "../../hooks/useZkIdentity";
import { Group } from "@semaphore-protocol/group";
import { SolidityProof, generateProof, packToSolidityProof } from "@semaphore-protocol/proof";
import { castVote, createPoll, getPoll, getPolls } from "../../services/votingApi";
import { keccak256 } from "ethers/lib/utils.js";
import { BigNumber } from "ethers";
import config from "../../constants";
import { abi } from "../../contracts/abi/SemaphoreLensVoting.json";
import { fetchMetadata } from "../../utils";
import PollPreview, { Poll } from "./poll";
import { PollsList } from "./pollsList";
import { useCastVote } from "../../hooks/useCastVote";
import { getBadgeBalance } from "../../services/zkApi";
import useCreatePoll from "../../hooks/usePoll";
const DEFAULT_COORDINATOR = "0x0000000000000000000000000000000000000000";

export const PollsPage = (props: Record<string, any>) => {
  const { chain } = useNetwork();
  const {data: signer, isSuccess } = useSigner();
  const chainId = chain?.id || "localhost";
  const { profile, profileId } = useLensProfile();
  // const { identity, commitment, createIdentity } = useZkIdentity();
  const [ pollId, setPollId ] = useState<string | null>();
  // const [ poll, setPoll ] = useState<Poll | null>();
  const [ polls, setPolls ] = useState<Poll[] | null>();
  const [ selectedPoll, setSelectedPoll ] = useState<Poll | undefined>();
  const { proof, solidityProof, status, generateVoteProof } = useCastVote();
  const [ isVerified, setIsVerified ] = useState<boolean | null>(null);
  const { status: createPollStatus , setPoll } = useCreatePoll();
  const inputPollId = useRef<HTMLInputElement>(null);
  // const [ status, setStatus ] = useState<string | null>();

  // get poll URI from chain
  const { data, refetch } = useContractRead({
    abi: abi,
    address: config.contracts[chainId].lensVoting,
    functionName: "getPollContentURI",
    args: [pollId]
  });

  // useEffect(() => {
  //   if (proof) {
  //     console.log('proof is generated: ', proof);
  //     setStatus('proof is generated');
  //   }
  // }, [proof]);

  useEffect(() => {
    async function _getPolls() {
      const polls = await getPolls(100);
      // console.log('polls: ', JSON.stringify(polls));
      setPolls(polls as Poll[]);
    }
    _getPolls().catch(console.error);
  }, []);

  useEffect(() => {
    async function _getPoll () {
      if (pollId) {
        let poll = await getPoll(pollId);
        console.log('poll: ', poll);
        if (!poll) {
          console.log('poll does not exist in db, checking onChain Metadata');
          const result = await refetch();
          console.log('contentURI: ', result.data);
          if (!result.data || String(result.data) === '') {
            console.log('no contentURI');
            return;
          }
          const contentURI: string = result.data as string;
          if (contentURI) {
            const metadata = await fetchMetadata(contentURI);
            console.log('metadata: ', metadata);
            poll = metadata;
            // setSelectedPoll(poll);
          }
        }
        
        setSelectedPoll(poll);
      }
    }
    _getPoll().catch(console.error);
  }, [pollId]);

  useEffect(() => {
    async function _getIsVerified () {
      const balance = await getBadgeBalance(profileId);
      console.log('balance: ', balance);
      setIsVerified((balance && balance.gt(0)));
    }
    _getIsVerified().catch(console.error);
  }, [profileId]);

  function handleSelectPoll (ev: any) {
    console.log('handleSelectPoll: ', ev);
    setPollId(ev);
  }

  function handleOnVote (communityId: string, pollId: string, nonce?: string) {
    console.log('handleOnVote: ', pollId);
    generateVoteProof(communityId, pollId, nonce? nonce : '1');
  }

  function handleGetVerified (ev: any) {
    console.log('handleGetVerified: ', ev);
    if (profileId && polls) {
      console.log('creating poll', inputPollId.current?.value);
      if (!inputPollId.current?.value) {
        console.log('no pollId');
        return;
      }
      setPoll({
        id: inputPollId.current?.value,
        profileId: profileId,
        communityId: '19',
        coordinator: DEFAULT_COORDINATOR,
        threshold: 66,
        yesVotes: 0
      })
    }
  }

  return (
    <TerminalCard cardHeader={`Polls Wrapper: ID: ${profileId} - ${profile?.handle} - verified? ${isVerified}`}>
      <Grid container spacing={2} alignItems="center">
        <Grid xs={12}>
          <input style={{maxWidth: "240px"}} type="text" name="pollId" placeholder="PollId: " ref={inputPollId} />
          <Button  variant="contained" onClick={handleGetVerified}>+ Get Verified</Button>
        </Grid>
        <Grid xs={8}>
          <PollsList polls={polls? polls : [] as Poll[]} onSelectedPoll={handleSelectPoll} />
        </Grid>
        <Grid xs={4}>
        <p>{createPollStatus}</p>
         <PollPreview poll={selectedPoll} onVote={handleOnVote} />
         <p>{status}</p>
        </Grid>
      </Grid>
    </TerminalCard>
  );
}