import { Group } from "@semaphore-protocol/group";
import { ethers, BigNumberish, EventFilter, BigNumber } from "ethers";
import { Identity } from "@semaphore-protocol/identity";
import { generateProof, packToSolidityProof, verifyProof } from "@semaphore-protocol/proof";
import { ILensBadge__factory, SemaphoreLensVoting, SemaphoreLensVoting__factory } from "../contracts/typechain";
import { DataTypes } from '../contracts/typechain/contracts/extensions/SemaphoreLensVoting';
import { generatePollMetaAndUploadToIPFS, generateZkCommunityMetaAndUploadToIPFS } from "./ipfs";
import { ILensHub__factory, IERC721Enumerable__factory } from "../contracts/types";

const semaphoreLensVotingAddress = process.env.REACT_APP_LENS_VOTING_CONTRACT_ADDRESS || '';
const lensHubAddress = process.env.REACT_APP_LENS_HUB_CONTRACT_ADDRESS || '';
const lensBadgeAddress = process.env.REACT_APP_LENS_BADGE_CONTRACT_ADDRESS || '';

const rpcUrl = process.env.REACT_APP_RPC_URL || '';
const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

const semaphoreLensVoting = SemaphoreLensVoting__factory.connect(semaphoreLensVotingAddress, provider);
const lensHubNFT = IERC721Enumerable__factory.connect(lensHubAddress, provider);
const lensBadge = ILensBadge__factory.connect(lensBadgeAddress, provider);

export async function createProof (communityId: string, identity: Identity, signal: string) {
  const onChainRoot = await semaphoreLensVoting.getMerkleTreeRoot(communityId);
  if (!onChainRoot) {
    throw new Error('Community does not exist');
  }

  const community = await getCommunityById(communityId);
  if (!community) {
    throw new Error('Could not fetch community');
  }

  const proof = await generateProof(identity, community, community?.root, signal);
  const solidityProof = packToSolidityProof(proof.proof);
  return {proof, solidityProof};
}

async function getCircleMeta (contentURI: string) {
  if (contentURI.startsWith('ipfs://')) {
    const ipfsHash = contentURI.replace('ipfs://', '');
    contentURI = `https://ipfs.io/ipfs/${ipfsHash}`;
  }

  const response = await fetch(contentURI, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const circleMeta = await response.json();
  return circleMeta;
}

export async function getBadgeBalance (profileId: string) {
  const ownerAddress = await lensHubNFT.ownerOf(profileId);
  const balance = await lensBadge.balanceOf(ownerAddress);
  return balance;
}

export async function getCommunityById (communityId: string) {
  const onChainRoot = await semaphoreLensVoting.getMerkleTreeRoot(communityId);
  if (!onChainRoot) {
    return null;
  }

  const community = new Group();
  const contentURI = await semaphoreLensVoting.getCommunityContentURI(communityId);
  const communityMeta = await getCircleMeta(contentURI);
  if (!communityMeta) {
    throw new Error('community Meta not found');
  }

  community.addMembers(communityMeta.data.members);

  if (BigNumber.from(community.root) !== onChainRoot) {
    throw new Error('Circle root does not match on-chain root');
  }

  return community;
}


// async function createVotingGroup (communityId: string, signerAddress: string) {
//   const onChainRoot = await semaphoreLensVoting.getMerkleTreeRoot(communityId);
//   if (onChainRoot) {
//     throw new Error('Community already exists');
//   }

//   const community = new Group();
//   const contentURI = await generateZkCommunityMetaAndUploadToIPFS(communityId, community);
//   const params: DataTypes.CreateCommunityParamsStruct = {
//     communityId: communityId,
//     merkleTreeDepth: 20,
//     contentURI: contentURI,
//     initialvoters: []
//   }

//   const tx = await semaphoreLensVoting.createVotingGroup(params);
//   return tx.hash;
// }

async function createPoll (communityId: string, pollId: string, signerAddress: string, profileId: string, threshold: number) {
  const onChainCoordinator = await semaphoreLensVoting.getPollCoordinator(pollId)
  if (!onChainCoordinator) {
    throw new Error('Poll already exists');
  }

  const community = await getCommunityById(communityId);
  if (!community) {
    throw new Error('Could not fetch community');
  }

  const contentURI = await generatePollMetaAndUploadToIPFS(
    pollId,
    communityId,
    profileId,
    threshold,
    signerAddress
  );

  const params: DataTypes.CreatePollParamsStruct = {
    communityId: communityId,
    pollId: pollId,
    contentURI: contentURI,
    coordinator: signerAddress,
    merkleTreeDepth: 20,
    profileId: profileId,
    threshold: threshold
  };
  const tx = await semaphoreLensVoting.createPoll(params);
  return tx.hash;
}