import React, { FC, useEffect, useState } from 'react';
import { useNetwork, useSigner, useSignTypedData } from 'wagmi'
import { Identity } from '@semaphore-protocol/identity';
import { createCreatePollTypedData, getPoll, createPoll } from '../services/votingApi';

const ZK_IDENTITY_KEY = process.env.REACT_APP_ZK_IDENTITY_KEY || 'zk_identity_08';
const ZK_IDENTITY_SEED = process.env.REACT_APP_ZK_IDENTITY_SEED || 'Please create my zkVerify identity, zk_verify';
import { Poll } from '../components/polls/poll';

const useCreatePoll = () => {
  const [ poll, setPoll ] = useState<Poll | null>(null);
  const [ pollId, setPollId ] = useState<string | null>(null);
  const { chain } = useNetwork();
  const {data: signer, isSuccess } = useSigner();
  const chainId = chain?.id || "localhost";
  const { signTypedData, signTypedDataAsync } = useSignTypedData();
  const [ signature, setSignature ] = useState<string | null>(null);
  const [ status, setStatus ] = useState<string | null>(null);

  useEffect(() => {
    async function _signPoll () {
      if (poll) {
        // check if the poll exists
        const alreadyExists = await getPoll(poll.id);
        
        if (alreadyExists) {
          console.log('poll already exists');
          return;
        }
        let signerAddress = await signer?.getAddress()
        if (!signerAddress) {
          console.log('no signer address');
          return;
        }

        // create the poll typed data
        const { domain, types, value } = await createCreatePollTypedData(poll.communityId, poll.profileId, signerAddress);
        console.log('typed data: ', domain, types, value);

        // sign the typed data
        const signature = await signTypedDataAsync({
          domain,
          types,
          value,
        })

        console.log('signature: ', signature);
        setSignature(signature);
      }
    }
    console.log('poll: ', poll);
    _signPoll().catch(console.error);
  }, [poll]);

  useEffect(() => {
    async function _createPoll () {
      let signerAddress = await signer?.getAddress();
      if (poll && signature && signerAddress) {
        setStatus('creating poll... this could take a minute. the pollId will be displayed here when it is created.');
        let resp = await createPoll(poll.communityId, poll.profileId, signerAddress, signature);
        console.log('create poll response: ', resp);
        setStatus(`pollId: ${resp}, Please reload the page to see the poll in the list.`);
      }
    }

    _createPoll().catch(console.error);
  }, [poll, signature]);

  return { poll, setPoll, status };
}

export default useCreatePoll;