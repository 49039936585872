const config: Record<string, any> = {
  contracts: {
    localhost: {
      lensHub: '0x1A1FEe7EeD918BD762173e4dc5EfDB8a78C924A8',
      dispatcher:''
    },
    "80001": {
      lensHub: '0x60Ae865ee4C725cd04353b5AAb364553f56ceF82',
      dispatcher:'0xD9bcb5871c4c859583e4B8c8526Cf097a1e322F3',
      lensVoting: '0x0E85cD6d41c27cd0ce572824feBd953fFd55B2C5'
    },
    // playground addresses
    // "80001": {
    //   lensHub: '0x7582177F9E536aB0b6c721e11f383C326F2Ad1D5',
    //   dispatcher:'0xD9bcb5871c4c859583e4B8c8526Cf097a1e322F3'
    // },
    "137": {
      lensHub: '0xDb46d1Dc155634FbC732f92E853b10B288AD5a1d',
      dispatcher:'0x8B258d88EA75B98dDad663a3f7355D2291De593C'
    }
  },
  urls: {
    localhost: {
      setDispatcher: "http://localhost:9000/setDispatcher",
      getShortCode: "http://localhost:9000/enableDispatcher",
      checkShortCode: "http://localhost:9000/checkcode",
      connectTelegram: "http://localhost:9000/connect-telegram",
    },
    "80001": {
      setDispatcher: "http://localhost:9000/setDispatcher",
      getShortCode: "http://localhost:9000/enableDispatcher",
      checkShortCode: "http://localhost:9000/checkcode",
      connectTelegram: "http://localhost:9000/connect-telegram",
    },
    "137": {
      setDispatcher: "https://lens.zk3.io/setDispatcher",
      getShortCode: "https://lens.zk3.io/enableDispatcher",
      checkShortCode: "https://lens.zk3.io/checkcode",
      connectTelegram: "https://lens.zk3.io/connect-telegram",
    }
  }
}

export default config;