import React, { FC, useEffect, useState } from 'react';
import { BigNumberish, ethers } from 'ethers';
// import { localhostProvider } from '../appConfig';
// import { useBalance, useEthersAdaptorFromProviderOrSigners } from 'eth-hooks';
// import { TUpdateOptions } from 'eth-hooks/models';
// import { useEthersAppContext } from 'eth-hooks/context';
import { getLoginChallenge, authenticateUser } from '../services/lensApi';
// import useLensProfile from '../hooks/useLensProfile';
import { useNetwork, useSigner } from 'wagmi'
import { verifyJwt } from '../services/lensApi';


const useLensLogin = () => {
  // const etherContext = useEthersAppContext();
  // const [adaptor] = useEthersAdaptorFromProviderOrSigners(etherContext.provider);
  const { chain, chains } = useNetwork();
  const chainId = chain?.id || "localhost";
  const { data: signer, isSuccess } = useSigner({
    onSuccess(data) {
      // console.log('Success', data)
    },
  });

  const [status , setStatus] = useState("logged out");
  const [authMsg, setAuthMsg] = useState("");
  const [authSig, setAuthSig] = useState("");
  const [authObj, setAuthObj] = useState({
    accessToken: "",
    refreshToken: ""
  });
  
  const token = localStorage.getItem('auth_token')
  // const {profileId, profile} = useLensProfile()

  useEffect(() => {
    async function authenticate () {
      if (authSig && authSig.length > 0 && signer) {
        let address = await signer.getAddress();
        let resp = await authenticateUser(address, authSig)
        if (resp.data && resp.data.authenticate && resp.data.authenticate.accessToken) {
          localStorage.setItem('auth_token', resp.data.authenticate.accessToken);
          // setToken(resp.data.authenticate.accessToken);
          setAuthObj(resp.data.authenticate);
          setStatus("Authenticated")
          // const res = await fetch(`http://localhost:9000/verifyJwt`, {
          //   method: "POST",
          //   body: JSON.stringify(resp.data.authenticate),
          //   headers: {
          //     'Content-type': 'application/json'
          //   },
          //   credentials: 'include'
          // }).then((res) => res.json());
          // console.log('got verifyJwt resp: ', res);
        }
        // console.log('auth response: ', resp)
      }
    }

    setStatus("Authenticating....")
    authenticate().catch(console.error);
  }, [authSig, isSuccess])


  useEffect(() => {
    async function signChallenge () {
      if (!signer || !isSuccess) {
        console.error('no adaptor');
        return
      }

      if (authMsg.length < 1) {
        console.log('no authMsg yet');
        return;
      }
      // let isValid = await verifyJwt(token);
      // console.log('isValid: ', isValid);
      
      // if (isValid && isValid.data && isValid.data.verify) {
        // setStatus("Authenticated")
        // return;
      // }

      let sig = await signer.signMessage(authMsg);
      if (sig)
        setAuthSig(sig)
      console.log('signature: ', sig);
    }
    setStatus("Awaiting Signature...")
    signChallenge().catch(console.error)
  }, [authMsg, isSuccess])


  useEffect(() => {
    async function getChallenge () {
      if (!signer || !isSuccess) {
        console.error('no adaptor');
        // setStatus("Logged out")
        return
      }
      
      let isValid: Record<string, any> = {}
      if (token) {
        // console.log('accessToken: ', token);
        isValid = await verifyJwt(token);
        // console.log('isValid: ', isValid);
      }

      if (isValid && isValid.data && isValid.data.verify) {
        setStatus("Authenticated")
        return;
      }

      let address = await signer.getAddress();
      let response = await getLoginChallenge(address)
      console.log('login challenge resp: ', response);
      let challengeText = response?.data?.challenge?.text
      console.log('challenge text', challengeText);
      setAuthMsg(challengeText);
    }
    setStatus("Not Logged in")
    getChallenge().catch(console.error)
  }, [signer])

  useEffect(() => {
    async function verifyToken () {
      if (token && token.length < 1) {
        console.log('no accessToken yet');
        return;
      }

      const res = await verifyJwt(token as string);
      // console.log('got verifyJwt resp: ', res);
    }
    verifyToken().catch(console.error)
  }, [])


  return {
    status,
    authMsg,
    authSig,
    authObj
  }
}

export default useLensLogin;