import { FC, useEffect, useRef, useState } from "react";
import { TerminalCard } from "../terminalCard";
import useLensProfile from "../../hooks/useLensProfile";
import { useNetwork, useSigner } from "wagmi";
import { signTypedData } from "wagmi/actions";
import Grid from '@mui/material/Unstable_Grid2';
import { Identity } from "@semaphore-protocol/identity";
// import useZkIdentity from "../../hooks/useZkIdentity";
import { DataGrid, GridApi, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { getPolls, getPoll, createPoll as createPollApi } from "../../services/votingApi";
import { ethers, BigNumberish } from "ethers";
import { Poll } from "./poll";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-main': {
    // backgroundColor: theme.palette.mode === 'dark' ? '#1d1d1d' : '#fff',
    // color: theme.palette.mode === 'dark' ? '#e8e9ed' : '#000',
    fontFamily: `Menlo, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace,
    serif`,
  }
}));

type Props = {
  polls : Poll[]
  onSelectedPoll: (id: string) => void
}

export const PollsList: FC<Props> = ({ polls, onSelectedPoll }) => {
  
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 130, sortable: true },
    { field: 'communityId', headerName: 'Community ID', width: 130 },
    { field: 'profileId', headerName: 'Profile ID', width: 130 },
    { field: 'coordinator', headerName: 'Coordinator', width: 130 },
    // { field: 'yesVotes', headerName: 'Yes Votes', width: 130 },
    // { field: 'noVotes', headerName: 'No Votes', width: 130 },
    // { field: 'status', headerName: 'Status', width: 130 },
    // { field: 'startedAt', headerName: 'Started At', width: 130 }
  ];

  return (
    <TerminalCard  cardHeader={`Polls List`}>
      <div style={{ height: 400, width: '100%' }}>
        <StyledDataGrid rows={polls} columns={columns} pageSize={5} onSelectionModelChange={(newSelectionModel) => {
          console.log('newSelectionModel', newSelectionModel);
          onSelectedPoll(newSelectionModel[0] as string);
        }}/>
      </div>
    </TerminalCard>
  );
}