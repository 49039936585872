import { useEffect, useState } from "react";
import { useNetwork, useSigner, useContractRead } from "wagmi";
import { Group } from "@semaphore-protocol/group";
import { getCommunity } from "../services/votingApi";
import { BigNumber } from "ethers";
import config from "../constants";
import { abi } from "../contracts/abi/SemaphoreLensVoting.json";
import { Community } from "../components/community/community";
import { fetchMetadata } from "../utils";

export const useCommunity = () => {
  const { chain } = useNetwork();
  const chainId = chain?.id || "localhost";
  const [ zkgId, setZkgId ] = useState<string | null>();
  const [ zkg, setZkg ] = useState<Group | null>();
  const [ selectedCommunity, setSelectedCommunity ] = useState<Community | undefined>();

  // get community URI from chain
  const { data, refetch } = useContractRead({
    abi: abi,
    address: config.contracts[chainId].lensVoting,
    functionName: "getCommunityContentURI",
    args: [zkgId]
  });

  const { data: root, refetch: refetchRoot } = useContractRead({
    abi: abi,
    address: config.contracts[chainId].lensVoting,
    functionName: "getMerkleTreeRoot",
    args: [zkgId]
  });

  useEffect(() => {
    async function _getCommunity () {
      console.log('Lens Voting Address: ', config.contracts[chainId].lensVoting);
      if (zkgId) {
        let community = await getCommunity(zkgId);
        console.log('community: ', community);
        if (!community) {
          console.log('community does not exist in db, checking onChain Metadata');
          const result = await refetch();
          console.log('contentURI: ', result.data);
          if (!result.data || String(result.data) === '') {
            console.log('no contentURI');
            return;
          }
          const contentURI: string = result.data as string;
          if (contentURI) {
            const metadata = await fetchMetadata(contentURI);
            console.log('metadata: ', metadata);
            community = metadata;
            // setSelectedCommunity(community);
          }
        }
        const zkg = new Group();
        community.members.forEach((member: string) => {
          zkg.addMember(BigNumber.from(member).toBigInt());
        });
        setSelectedCommunity(community);
        const rootResp = await refetchRoot();
        console.log('zkg root: ', zkg.root.toString());
        let rootOnChain = rootResp.data as BigNumber;
        console.log('rootOnChain: ', rootOnChain.toString());
        if (rootOnChain.toString() === zkg.root.toString()) {
          console.log('root matches');
        } else {
          console.log('root does not match');
        }
        setZkg(zkg);
      }
    }
    _getCommunity().catch(console.error);
  }, [zkgId]);

  function setCommunityId (ev: any) {
    console.log('handleSelectCommunity: ', ev);
    setZkgId(ev);
  }

  return {
    zkgId,
    zkg,
    metadata: selectedCommunity,
    setCommunityId
  };
}