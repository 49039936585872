import React, { FC, useEffect, useState, useRef } from 'react';
import { useNetwork, useSigner } from 'wagmi'
import { Identity } from '@semaphore-protocol/identity';
const ZK_IDENTITY_KEY = process.env.REACT_APP_ZK_IDENTITY_KEY || 'zk_identity_08';
const ZK_IDENTITY_SEED = process.env.REACT_APP_ZK_IDENTITY_SEED || 'Please create my zkVerify identity, zk_verify';

const useZkIdentity = () => {
  const { chain, chains } = useNetwork();
  const chainId = chain?.id || "localhost";
  const { data: signer, isSuccess } = useSigner({
    onSuccess(data) {
      // console.log('Success', data)
    },
  });

  const [status , setStatus] = useState("logged out");
  const storedIdentity = localStorage.getItem(ZK_IDENTITY_KEY);
  const [identity, setIdentity] = useState<Identity | null>(null);
  const [ commitment, setCommitment ] = useState<string | null>(null);
  const [ sigMsg, setSigMsg ] = useState<string | null>(null);
  const [ currentNonce, setCurrentNonce ] = useState<string | null>(null);
  const alreadyCalled = useRef<boolean>(false);
  // if (storedIdentity) {
  //   const id = new Identity(storedIdentity);
  //   setIdentity(id);
  //   setCommitment(id.generateCommitment().toString());
  //   setStatus("logged in");
  // }
  alreadyCalled.current = false;
  
  useEffect(() => {
    async function storeIdentity() {
      if (identity) {
        localStorage.setItem('ZK_IDENTITY_KEY', identity.toString());
        // setIdentity(identity);
        // setCommitment(commitment);
      }
    }
    if (identity && commitment) {
      storeIdentity().catch(console.error);
    }
  }, [identity, commitment]);

  useEffect(() => {
    async function getIdentity() {    
      if (storedIdentity) {
        const identity = new Identity(storedIdentity);
        setIdentity(identity);
        setCommitment(identity.getCommitment().toString());
        setStatus("logged in");
        return;
      }

      if (sigMsg && alreadyCalled.current === false) {
        const sig = await signer?.signMessage(sigMsg);
        console.log('sig', sig);
        const id = new Identity(sig);
        setIdentity(id);
        setCommitment(id.getCommitment().toString());
        alreadyCalled.current = true;
        // ref.current = id;
      }
      // const identityBackup = localStorage.getItem('zk_identity_01');
      // if (identityBackup) {
      //   setIdentity(new Identity(identityBackup));
      // } else {
      // }
    }
    getIdentity().catch(console.error);
  }, [isSuccess, signer, sigMsg]);

  useEffect(() => {
    let storedId = localStorage.getItem(ZK_IDENTITY_KEY);
    console.log('storedId', storedId);
    if (storedId) {
      let id = new Identity(storedId)
      setIdentity(id);
      setCommitment(id?.getCommitment()?.toString());
    } else {
      // if (alreadyCalled.current === false) {
      //   alreadyCalled.current = true;
      //   createIdentity("2");
      // }
      
    }
  }, [isSuccess, signer]);
  

  const createIdentity = (nonce: string) => {
    console.log('createIdentity');
    if (!identity || currentNonce !== nonce) {
      alreadyCalled.current === false;
      setSigMsg(`${ZK_IDENTITY_SEED}_${nonce}`);
      setCurrentNonce(nonce);
    } else {
      console.log(identity);
      console.log(commitment);

    }
    // setCommitment(commitment.toString());
  }

  const overwriteIdentity = () => {
    console.log('overwriteIdentity');
    // todo: store nonce in localstorage
    setSigMsg(`${ZK_IDENTITY_SEED}_${Math.floor(Math.random() * 1000000)}`);
  }


  return {
    status,
    identity,
    commitment,
    createIdentity,
    overwriteIdentity
  }
}

export default useZkIdentity;