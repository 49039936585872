import { ApolloClient, InMemoryCache, gql, HttpLink, ApolloLink } from '@apollo/client'
import { SolidityProof } from '@semaphore-protocol/proof';
import { BigNumber, BigNumberish, TypedDataDomain } from 'ethers';

// const { CHAIN_ID, ZK3_API_URL } = process.env;
const CHAIN_ID = process.env.CHAIN_ID as string;
// remove pre merge
const APIURL = process.env.REACT_APP_ZK3_API_URL || 'http://localhost:4000';
// ----------------
const httpLink = new HttpLink({uri: APIURL});
const authLink = new ApolloLink((operation, forward) => {
  const token =  localStorage.getItem('auth_token')
   // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
  headers: {
    'x-access-token': token ? `Bearer ${token}`: '',
  }
}));

  return forward(operation);
})

const config = {
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
}
export let apolloClient= new ApolloClient(config)

export async function getPolls (limit = 100, offset=0): Promise<Record<string, any>[]> {
  const query = gql`query Polls {
    polls {
      id
      communityId
      profileId
      yesVotes
      noVotes
      coordinator
      status
      startedAt
    }
  }`
  const resp = await apolloClient.query({
    query: query,
  })

  return resp.data.polls;
}

export async function getPoll (id: string) {
  const query = gql`query GetPoll($id: ID!) {
    getPoll(pollId: $id) {
      id
      communityId
      profileId
      yesVotes
      noVotes
      coordinator
      status
      startedAt
      threshold
    }
  }`
  const resp = await apolloClient.query({
    query: query,
    variables: {
      id
    }
  })
  console.log('getPoll resp: ', resp.data)
  return resp.data.getPoll;
}

export async function getPollState (pollId: BigNumberish, communityId: BigNumberish) {
  const query = gql`query GetPollState($communityId: ID!, $pollId: ID!) {
    getPollState(communityId: $communityId, pollId: $pollId) {
      pollId
      communityId
      profileId
      yesVotes
      state
      threshold
    }
  }`

  const resp = await apolloClient.query({
    query: query,
    variables: {
      communityId,
      pollId
    }
  })

  return resp.data.getPollState;
}

export async function getPollsByCommunityId (communityId: string, limit=10, offset=0) {
  const query = gql`query PollsByCommunityId($communityId: ID!) {
    pollsByCommunityId(communityId: $communityId) {
      id
      communityId
      profileId
      yesVotes
      noVotes
      coordinator
      status
      startedAt
    }
  }`

  const resp = await apolloClient.query({
    query: query,
    variables: {
      communityId
    }
  })

  return resp.data.pollsByCommunityId;
}

export async function getPollsByProfileId (profileId: BigNumberish, limit=10, offset=0) {
  const query = gql`query GetPollsByProfileId($profileId: ID!) {
    getPollsByProfileId(profileId: $profileId) {
      id
      communityId
      profileId
      yesVotes
      noVotes
      coordinator
      status
      startedAt
    }
  }`

  const resp = await apolloClient.query({
    query: query,
    variables: {
      profileId
    }
  })
  console.log('resp: ', resp);
  return resp.data.getPollsByProfileId;
}

export async function getPollsByProfileIdAndCommunityId (communityId: string, profileId: string, limit=10, offset=0) {
  const query = gql`query GetPollsByProfileIdAndCommunityId($communityId: ID!, $profileId: ID!) {
    GetPollsByProfileIdAndCommunityId(communityId: $communityId, profileId: $profileId) {
      id
      communityId
      profileId
      yesVotes
      noVotes
      coordinator
      status
      startedAt
    }
  }`

  const resp = await apolloClient.query({
    query: query,
    variables: {
      profileId,
      communityId
    }
  })

  return resp.data.GetPollsByProfileIdAndCommunityId;
}


// --------------------------------------------
// mutations
// --------------------------------------------

export async function createPoll (communityId: string, profileId: string, submittedBy: string, signature: string) {
  const query = gql`mutation CreatePoll($communityId: String!, $profileId: String!, $submittedBy: String!, $signature: String!) {
    createPoll(communityId: $communityId, profileId: $profileId, submittedBy: $submittedBy, signature: $signature)
  }
  `

  const resp = await apolloClient.mutate({
    mutation: query,
    variables: {
      communityId,
      profileId,
      submittedBy,
      signature
    }
  })

  return resp.data.createPoll;
}

export async function castVote (identityCommitment: string, vote: string, pollId: string, communityId: string, nullifierHash: string, solidityProof: SolidityProof, signature: string) {
  const query = gql`mutation CastVote($identityCommitment: String!, $vote: String!, $pollId: String!, $communityId: String!, $nullifierHash: String!, $solidityProof: [String], $signature: String!) {
    castVote(identityCommitment: $identityCommitment, vote: $vote, pollId: $pollId, communityId: $communityId, nullifierHash: $nullifierHash, solidityProof: $solidityProof, signature: $signature)
  }`

  const resp = await apolloClient.mutate({
    mutation: query,
    variables: {
      identityCommitment,
      vote,
      pollId,
      communityId,
      nullifierHash,
      solidityProof,
      signature
    }
  });

  return resp.data.castVote;
}

export async function addVoter (identityCommitment: string, communityId: string, profileId: string, contentURI: string, signature: string) {
  const query = gql`mutation AddVoter($identityCommitment: String!, $communityId: String!, $profileId: String!, $contentURI: String!, $signature: String!) {
    addVoter(identityCommitment: $identityCommitment, communityId: $communityId, profileId: $profileId, contentURI: $contentURI, signature: $signature)
  }`

  const resp = await apolloClient.mutate({
    mutation: query,
    variables: {
      identityCommitment,
      communityId,
      profileId,
      contentURI,
      signature
    }
  });
  console.log('addVoter resp: ', resp);

  return resp.data.addVoter;
}

export async function claimBadge (communityId: string, pollId: string, ownerAddress: string, signature: string) {
  const query = gql`mutation ClaimBadge($communityId: ID!, $pollId: ID!, $ownerAddress: String!, $signature: String!) {
    claimBadge(communityId: $communityId, pollId: $pollId, ownerAddress: $ownerAddress, signature: $signature)
  }`

  const resp = await apolloClient.mutate({
    mutation: query,
    variables: {
      communityId,
      pollId,
      ownerAddress,
      signature
    }
  });

  return resp.data.claimBadge;
}

export async function getCommunity (communityId: string) {
  const query = gql`query Community($communityId: ID!) {
    community(id: $communityId) {
      id
      name
      description
      members
      coordinator
    }
  }`
  const resp = await apolloClient.query({
    query: query,
    variables: {
      communityId
    }
  })

  return resp.data.community;
}

export async function getCommunities () {
  const query = gql`query Community {
    communities {
      id
      name
      description
      members
      coordinator
    }
  }`

  const resp = await apolloClient.query({
    query: query
  })

  return resp.data.communities;
}

export async function createCommunity (communityId: string, name: string, description: string, coordinator: string, members: string[], signature: string) {
  const query = gql`mutation CreateCommunity($communityId: ID!, $name: String!, $description: String!, $coordinator: String!, $members: [String], $signature: String) {
    createCommunity(communityId: $communityId, name: $name, description: $description, coordinator: $coordinator, members: $members, signature: $signature)
  }`

  const resp = await apolloClient.mutate({
    mutation: query,
    variables: {
      communityId,
      name,
      description,
      coordinator,
      members,
      signature
    }
  });

  return resp.data.createCommunity;
}

// --------------------------------------------
// typed Data generators for signing
// --------------------------------------------

/**
 * createCreatePollTypedData - creates the typed data for the createPoll function
 * @param communityId the semaphore group id
 * @param profileId the profile id of the user
 * @param submittedBy the address of the user
 * @returns typed data for the createPoll function
 */
export function createCreatePollTypedData (communityId: BigNumberish, profileId: BigNumberish, submittedBy: string) {
  const domain = {
    name: 'Lens Verify',
    version: '1',
    chainId: CHAIN_ID,
    verifyingContract: '0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC' as `0x${string}`,
  };

  const types = {
    CreatePoll: [
      { name: 'communityId', type: 'uint256' },
      { name: 'profileId', type: 'uint256' },
      { name: 'submittedBy', type: 'address' },
    ]
  };

  const value = {
    communityId,
    profileId,
    submittedBy,
  };

  return {
    domain,
    types,
    value,
  };
}
/**
 * createCastVoteTypedData - creates the typed data for the castVote function
 * @param identityCommitment the identity commitment of the voter
 * @param vote the vote of the voter (yes/no)
 * @param solidityProof the zkSNARK proof packed for solidity
 * @param pollId the poll id
 * @param communityId the sempahore group id
 * @param nullifierHash the nullifier hash of the vote
 * @returns typed data for the castVote function
 */
export function createCastVoteTypedData (
  identityCommitment: string,
  vote: string,
  solidityProof: string[],
  pollId: BigNumberish,
  communityId: BigNumberish,
  nullifierHash: BigNumberish
) {
  const domain = {
    name: 'Lens Verify',
    version: '1',
    chainId: CHAIN_ID,
    verifyingContract: '0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC' as `0x${string}`
  };

  const types = {
    CastVote: [
      { name: 'identityCommitment', type: 'uint256' },
      { name: 'vote', type: 'string' },
      { name: 'pollId', type: 'uint256' },
      { name: 'nullifierHash', type: 'uint256' },
      { name: 'communityId', type: 'uint256' },
      { name: 'solidityProof', type: 'string[]' },
    ]
  };

  const value = {
    identityCommitment,
    vote,
    pollId,
    nullifierHash,
    communityId,
    solidityProof,
  };

  return {
    domain,
    types,
    value,
  };

}

/**
 * createAddVoterTypedData - creates the typed data for the addVoter function
 * @param identityCommitment the identity commitment of the voter
 * @param profileId the profile id of the voter
 * @param communityId the semaphore group id
 * @returns the typed data
 */
export function createClaimBadgeTypedData (communityId: BigNumberish, pollId: BigNumberish, ownerAddress: string) {
  const domain = {
    name: 'Lens Verify',
    version: '1',
    chainId: BigNumber.from(CHAIN_ID).toNumber(),
    verifyingContract: '0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC'
  };

  const types = {
    ClaimBadge: [
      { name: 'communityId', type: 'uint256' },
      { name: 'pollId', type: 'uint256' },
      { name: 'ownerAddress', type: 'address' }
    ]
  };

  const value = {
    communityId,
    pollId,
    ownerAddress
  };

  return { domain, types, value };
}


/**
 * createAddVoterTypedData - creates the typed data for the addVoter function
 * @param identityCommitment the identity commitment of the user
 * @param profileId the profileId of the user
 * @param communityId the semaphore group id
 * @returns typed data for the addVoter function
 */
export function createAddVoterTypedData (identityCommitment: BigNumberish, profileId: BigNumberish, communityId: BigNumberish) {
  const domain = {
    name: 'Lens Verify',
    version: '1',
    chainId: CHAIN_ID,
    verifyingContract: '0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC' as `0x${string}`
  };

  const types = {
    AddVoter: [
      { name: 'identityCommitment', type: 'uint256' },
      { name: 'communityId', type: 'uint256' },
      { name: 'profileId', type: 'uint256' },
    ]
  };

  const value = {
    identityCommitment,
    communityId,
    profileId,
  };

  return {
    domain,
    types,
    value,
  };
}