import { useContext, useEffect, useState } from "react";
import { useNetwork, useSignTypedData, useSigner } from "wagmi";
// import useZkIdentity from "../hooks/useZkIdentity";
import { Group } from "@semaphore-protocol/group";
import { SolidityProof, generateProof, packToSolidityProof } from "@semaphore-protocol/proof";
import { castVote, createCastVoteTypedData, createCommunity, getCommunity } from "../services/votingApi";
import { keccak256 } from "ethers/lib/utils.js";
import { BigNumber } from "ethers";
import { useCommunity } from "./useCommunity";
import { IdentityContext } from "../identity";
import useZkIdentity from "./useZkIdentity";
import { Identity } from "@semaphore-protocol/identity";
const DEFAULT_COORDINATOR = "0x0000000000000000000000000000000000000000";
const ZK_IDENTITY_SEED = process.env.REACT_APP_ZK_IDENTITY_SEED || 'Please create my zkVerify identity, zk_verify';

export const useCastVote = () => {
  const { chain } = useNetwork();
  const {data: signer, isSuccess } = useSigner();
  const chainId = chain?.id || "localhost";
  const [ status, setStatus ] = useState<string>("");
  console.log('castVoter called')
  // const identity = useContext(IdentityContext);
  // const commitment = identity?.getCommitment().toString();
  const { identity, commitment, createIdentity } = useZkIdentity();
  const { zkgId, zkg, setCommunityId, metadata } = useCommunity();
  // const [ zkgId, setZkgId ] = useState<string | null>();
  // const [ zkg, setZkg ] = useState<Group | null>();

  const [ pollId, setPollId ] = useState<string | null>();
  const [ proof, setProof ] = useState<Record<string, any> | null>();
  const [ solidityProof, setSolidityProof ] = useState<SolidityProof | null>();
  const signal = keccak256(Buffer.from("1"));
  const [ nonce , setNonce ] = useState<string>("0");
  const [ signature, setSignature ] = useState<string | null>();
  const { signTypedData, signTypedDataAsync } = useSignTypedData();

  useEffect(() => {
    async function _signIt () {
      console.log('SignIt: ', proof, signal, pollId, zkgId, commitment)
      if (proof && commitment && signal && pollId && zkgId && solidityProof) {
        console.log('proof: ', proof);
        // const solidityProof = packToSolidityProof(proof.proof);
        console.log('submitting proof to backend relayer: ', commitment, solidityProof, proof.nullifierHash);
        const { domain, types, value } = createCastVoteTypedData(
          commitment, 
          signal, 
          solidityProof as string[], 
          BigNumber.from(pollId), 
          BigNumber.from(zkgId), 
          proof.nullifierHash as string
        );

        console.log('CastVoteTypedData value: ', value);
        const signature = await signTypedDataAsync({domain, types, value});

        console.log('signature: ', signature);
        setSignature(signature);
      }
    }
    _signIt().catch(console.error);
  }, [proof, zkgId, zkg, pollId, signal, nonce, solidityProof, commitment])

  useEffect(() => {
    async function _castIt () {
      console.log('castIt: ', proof, signal, pollId, zkgId, commitment)
      if (proof && commitment && signal && pollId && zkgId && solidityProof && signature) {
        console.log('proof: ', proof);
        // const solidityProof = packToSolidityProof(proof.proof);
        console.log('submitting proof to backend relayer: ', commitment, solidityProof, proof.nullifierHash, signature);
        const resp = await castVote(
          commitment, 
          signal, 
          pollId, 
          zkgId, 
          proof.nullifierHash as string, 
          solidityProof,
          signature
        );
        console.log('resp: ', resp);
        setStatus("success, vote casted successfully, tx: " + resp);
        clearProofs();
      }
    }

    _castIt().catch((e) => {
      console.error(e);
      setStatus("error, vote not casted");
      clearProofs();
    });
  }, [proof, zkgId, zkg, pollId, signal, nonce, solidityProof, commitment, signature])

  useEffect(() => {
    async function _getFullProof () {
      console.log('generating proof: ', identity, zkg, pollId, signal);
      if (identity && zkg && pollId && signal) {
        const proof = await generateProof(identity, zkg, pollId, signal);
        console.log('proof: ', proof);
        setProof(proof.publicSignals);
        setSolidityProof(packToSolidityProof(proof.proof));
        setStatus("proof generated");
        // const solidityProof = packToSolidityProof(proof.proof);
      }
    }
    _getFullProof().catch(console.error);
  }, [identity, zkg, pollId]);

  // useEffect(() => {
  //   if (!identity && isSuccess) {
  //     createIdentity();
  //   }
  // }, [identity, isSuccess]);

  function generateVoteProof (communityId: string, pollId: string, nonce: string) {
    createIdentity(nonce.toString());    
    setCommunityId(communityId);
    setPollId(pollId);
    console.log('nonce: ', nonce);
    nonce = nonce || "1";
    setNonce(nonce);
  }

  function clearProofs () {
    setProof(null);
    setSolidityProof(null);
  }

  return {
    proof,
    solidityProof,
    status,
    generateVoteProof
  };
};