// import { keccak256 } from "@ethersproject/keccak256";
// Why ethereumjs-utils and not etherproject
// because merkle-patricia-trie uses this one, and the only
// difference is this doesn't have the leading 0x char. 
// but they produce the same results. 
import fetch from 'cross-fetch';
import { parse as parseUrl } from "url";
import { Resolver } from "dns/promises";
const resolver = Resolver;
const isLocalIP = require("is-local-ip");

export async function fetchMetadata (url: string) {
  let uri: string
  if (url && url.toLowerCase().startsWith("ipfs://")) {
    uri = `https://apricot-peculiar-panda-110.mypinata.cloud/ipfs/${url.replace(/ipfs:\/\//, "")}`
  } else if (url && url.toLowerCase().startsWith("https://arweave.net")){
    uri = await arweaveUrl(url);
  } else {
    uri = url
  }

  const parsedUrl = await sanitizeUrl(uri)
  if (!parsedUrl) {
    console.log('bad post ', event);
    return
  }

  console.log('grabbing conent at ', uri);
  const resp = await fetch(uri, {redirect: "follow"});
  const content = await resp.json();
  console.log('content: ', content);
  return content;
}
export async function sanitizeUrl (url: string, testing = false) {
  let ips, urlObj, isLocal;  
  try {
    urlObj = parseUrl(url);
    if (urlObj.host) {
      ips = await resolver.resolve4(urlObj.hostname);
    }
  } catch (e) {
    console.error("error: ", e);
    throw e;
  }

    // This is mainly useful for local testing
    if (testing) {
      isLocal = false;
    } else {
      try {
        if (ips && ips.length) {
          isLocal = isLocalIP(ips[0]);
        } else {
          isLocal = true;
        }
      } catch (e) {
        console.error("isLocal Error", isLocal, e);
        throw e;
      }
    }
    if (isLocal) {
      // don't fire this webhook.
      console.log(
        `contentURI ${url} resolved to a localIP, resolved IP: ${ips}`
      );
      return null;
    } else {
      return urlObj.toString()
    }
}

// example
// arweaveUrl("https://arweave.net/eh6vA84nx2yzKHP2KlblQZ9RGw63v_8zt6HI4chzYGM").then(console.log).catch(console.error)
export async function arweaveUrl (url: string) : Promise<string> {
  let res = await fetch(url, {redirect: "manual"})
  if (res && res.headers && res.headers.has("location") && res.status === 302) {
    return String(res.headers.get('location')??[0])
  } else {
    console.error('arweave URL does not have the proper redirect header: ', res.headers);
    return url
  }
}