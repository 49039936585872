import React, { useState } from 'react';
import { createRoot } from "react-dom/client"
import 'terminal.css';
// import './dark.css';
import '@rainbow-me/rainbowkit/dist/index.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme as rainbowDarkTheme
} from '@rainbow-me/rainbowkit';
import { polygon, polygonMumbai } from 'wagmi/chains';
import {
  Chain,
  configureChains,
  createClient,
  WagmiConfig,
  useProvider,
  useSigner,
  useNetwork,
} from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import  useLensLogin from './hooks/useLensLogin';
import { Item } from "./components/terminalCard"


import { CreateIdentity, IdentityList } from "./components/identity"
import { CreateGroup } from "./components/group"
import { CreatePoll, PollsList } from "./components/polls"
import { CastVote } from "./components/cast-vote"
// import { CreatePost, CreateComment } from "./components/publication"

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AddVoter } from "./components/add-voter"
import { CommunityPage } from "./components/community/container"
import { PollsPage } from "./components/polls/container"
import { IdentityProvider } from "./identity"

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


export const Header = () => {
  const { data: signer, isError, isLoading } = useSigner()
  // console.log('data', signer);
  // let sig = signer?.signMessage('hello world');
  // console.log('sig: ', sig);
  const { status } = useLensLogin();
  return (
      <div style={{ position: "fixed", top: "10px", right: "10px" }}>
        <ConnectButton 
        chainStatus={{ smallScreen: "icon", largeScreen: "icon" }} showBalance={{ smallScreen: false, largeScreen: false }} 
        />
      </div>);
};

export const ConnectedApp = () => {
  return (
  <Item  className="terminal-card" style={{textAlign: "justify"}}>
    <header>ZkVerify v0.1.0</header>
  </Item>
  );
}

export const HomeMenu = () => {
  return (
  <Item  className="terminal-card">
    <header> 
      ZkVerify v0.1.0
      <Header />
    </header>
    <nav>
      {/* <ul>
        <li>
          <a href="/profile">Dashboard</a>
        </li>
        <li>
          <a href="/profile">Identity</a>
        </li>
        <li>
          <a href="/profile">Proofs</a>
        </li>
        <li>
          <a href="/profile">Connected Apps</a>
        </li>
        <li>
          <a href="/profile">Settings</a>
        </li>
      </ul> */}
    </nav>
  </Item>
  );
}


function App() {
  const { chains, provider } = configureChains(
    [polygon, polygonMumbai],
    [
      publicProvider()
    ]
  );
  
  const { connectors } = getDefaultWallets({
    appName: 'Zk3',
    chains,
  });
  
  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
  })
  const [ toggleId, setToggleId ] = useState(false);

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} theme={rainbowDarkTheme()}>
        <IdentityProvider>
        <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Box sx={{ flexGrow: 1 }} style={{marginTop: "60px"}}>
          <Grid container spacing={2} alignItems="center">
            <Grid xs={12}>
              <HomeMenu />
              {/* <CreateIdentity /> */}
              <PollsPage />
              {/* <CommunityPage /> */}
              {/* <CreatePoll /> */}
              <AddVoter />
              {/* <CastVote />
              <CreatePoll />
              <PollsList /> */}
              {/* <CreatePost /> */}
              {/* <CreateComment /> */}
              {/* <IdentityList /> */}
              {/* <CreateGroup /> */}
              {/* <SetDispatcher/>
              <ConnectedAppsList />
              <ConnectTelegram /> */}
            </Grid>
            
            {/* <Grid xs={4}>
              <Item>xs=4</Item>
            </Grid>
            <Grid xs={4}>
              <Item>xs=4</Item>
            </Grid>
            <Grid xs={8}>
              <Item>xs=8</Item>
            </Grid> */}
          </Grid>
        </Box>
      </ThemeProvider>
      </IdentityProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  )
}

const root = createRoot(document.getElementById("app")!)

root.render(
    <div>
        <App />
    </div>
)
