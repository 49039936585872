import { useContext, useEffect, useState } from "react";
import { useNetwork, useSigner, useSignTypedData } from "wagmi";
// import useZkIdentity from "../hooks/useZkIdentity";
import { Group } from "@semaphore-protocol/group";
import { SolidityProof, generateProof, packToSolidityProof } from "@semaphore-protocol/proof";
import { castVote, claimBadge, createClaimBadgeTypedData, createCommunity, getCommunity, getPoll } from "../services/votingApi";
import { keccak256 } from "ethers/lib/utils.js";
import { BigNumber, TypedDataDomain } from "ethers";
import { useCommunity } from "./useCommunity";
import { IdentityContext } from "../identity";
import { Poll } from "src/components/polls/poll";
import useZkIdentity from "./useZkIdentity";
const DEFAULT_COORDINATOR = "0x0000000000000000000000000000000000000000";

export const useClaimBadge = () => {
  const { chain } = useNetwork();
  const {data: signer, isSuccess } = useSigner(); 
  const chainId = chain?.id || "localhost";
  const [ status, setStatus ] = useState<string>("");
  console.log('useClaimBadge called')
  // const identity = useContext(IdentityContext);
  // const commitment = identity?.getCommitment().toString();
  const { identity, commitment, createIdentity } = useZkIdentity();

  const { zkgId, zkg, setCommunityId, metadata } = useCommunity();
  const [ poll, setPoll ] = useState<Poll | null>();
  const [ pollId, setPollId ] = useState<string | null>(); 
  const [ resp, setResp ] = useState<any | null>();
  const { data, signTypedData, signTypedDataAsync } = useSignTypedData();
  useEffect(() => {
    async function _getPoll () {
      console.log('getPoll: ', pollId);
      if (pollId) {
        const resp = await getPoll(pollId);
        console.log('resp: ', resp);
        setPoll(resp);
      }
    }
    _getPoll().catch(console.error);
  }, [pollId])

  useEffect(() => {
    async function _claimIt () {
      const ownerAddress = await signer?.getAddress();
      // const identity = useContext(IdentityContext);
      // const commitment = identity?.getCommitment().toString();
      console.log('claimIt: ', poll, commitment);
      if (poll && ownerAddress) {
        const { domain, types, value } = createClaimBadgeTypedData(BigNumber.from(poll.communityId), BigNumber.from(pollId), ownerAddress);
        
        const sig = await signTypedDataAsync({domain: {
          name: domain.name,
          version: domain.version,
          chainId: domain.chainId as number,
          verifyingContract: domain.verifyingContract as `0x${string}`,
        },
        types,
        value})

        console.log('sig: ', sig)
        // const sig = await signer?._signTypedData(domain, types, value);
        const res = await claimBadge(poll.communityId, poll.id, ownerAddress, sig);
        console.log('claim Badge res: ', res);
      }
    }
    _claimIt().catch(console.error);
  }, [poll])


  function triggerClaimBadge (pollId: string) {
    setPollId(pollId);
  }

  return {
    resp,
    triggerClaimBadge
  };
}