import { useEffect, useState } from "react";
import { TerminalCard } from "./terminalCard";
import useLensProfile from "../hooks/useLensProfile";
import { useNetwork, useSigner, useContractRead, useSignTypedData } from "wagmi";
import Grid from '@mui/material/Unstable_Grid2';
import useZkIdentity from "../hooks/useZkIdentity";
import { Group, Member } from "@semaphore-protocol/group";
import { SolidityProof, generateProof, packToSolidityProof } from "@semaphore-protocol/proof";
import { addVoter, castVote, createAddVoterTypedData, createCommunity, getCommunity } from "../services/votingApi";
import { keccak256 } from "ethers/lib/utils.js";
import { BigNumber } from "ethers";
import { abi } from "../contracts/abi/SemaphoreLensVoting.json";
import config from "../constants";
import { fetchMetadata } from "../utils";
import { generateZkCommunityMetaAndUploadToIPFS } from "../services/ipfs";
import { useCommunity } from "../hooks/useCommunity";
const DEFAULT_COORDINATOR = "0x0000000000000000000000000000000000000000";
// const LENS_VOTING_ADDRESS = process.env.REACT_APP_LENS_VOTING_CONTRACT_ADDRESS;

export const AddVoter = (props: Record<string, any>) => {
  const {
    zkgId,
    zkg,
    metadata,
    setCommunityId
  } = useCommunity();
  const { profile, profileId } = useLensProfile();
  const { signTypedData, signTypedDataAsync } = useSignTypedData();
  const [ voterCommitment, setVoterCommitment ] = useState<string | null>();
  const [ txHash, setTxHash ] = useState<string | null>();

  useEffect(() => {
    async function _addVoter() {
      console.log("_addVoter", zkgId, zkg, voterCommitment, metadata)
      if (zkgId && zkg && voterCommitment && metadata) {
        zkg.addMember(voterCommitment);
        const newMetadata = await generateZkCommunityMetaAndUploadToIPFS(zkgId, metadata.name, metadata.description, DEFAULT_COORDINATOR, zkg);
        console.log('newMetadata: ', newMetadata);
        // zeros are just testing values, will be replaced with real values
        const { domain, types, value } = createAddVoterTypedData(voterCommitment, profileId, zkgId);
        const signature = await signTypedDataAsync({domain, types, value});
        const resp = await addVoter(voterCommitment, zkgId, profileId, newMetadata, signature);
        console.log('addVoter resp: ', resp);
        setTxHash(resp);
      }
    }

    _addVoter().catch(console.error);
  }, [zkgId, zkg, voterCommitment, metadata, profileId])

  function handleAddVoter (ev: any) {
    ev.preventDefault();
    const data = new FormData(ev.target);
    const zkgId = data.get('zkgId');
    setVoterCommitment(data.get('commitment') as string);
    setCommunityId(zkgId as string);
  }

  return (
    <TerminalCard  cardHeader="Add Voter">
      <div>
        <Grid container spacing={2} alignItems="center">
          <Grid xs={6}>
            <form onSubmit={handleAddVoter}>
              <Grid container spacing={2} alignItems="center">
                <Grid xs={12}>
                  <input type="text" name="zkgId" placeholder="Group ID" />
                </Grid>
                <Grid xs={12}>
                  <input type="text" name="commitment" placeholder="Commitment" />
                </Grid>
                <Grid xs={12}>
                  <input className="btn btn-primary btn-ghost" type="submit" value="add Voter" />
                </Grid>
                <Grid xs={12}>
                  {/* <button className="btn btn-primary btn-ghost" onClick={generateProofOfMembership}>GenerateProof</button> */}
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid xs={6}>
            <p><small>root:</small>{(zkg)? zkg.root.toString(): null}</p>
            <p><small>txHash:</small>{(txHash)? txHash: null}</p>
            {/* <p aria-multiline={true}>{(metadata)? JSON.stringify(metadata): null}</p> */}
          </Grid>
        </Grid>
      </div>
    </TerminalCard>
    );
  };