import React, { Context, Provider, useEffect, PropsWithChildren } from 'react';
import { Identity } from '@semaphore-protocol/identity';
import useZkIdentity from './hooks/useZkIdentity';
import { useSignMessage } from 'wagmi';
const ZK_IDENTITY_SEED = process.env.REACT_APP_ZK_IDENTITY_SEED || 'Please create my zkVerify identity, zk_verify';

// todo - add a default value if it's set in the local storage
export const IdentityContext = React.createContext<Identity | null>(null);
export interface IdentityProviderProps extends PropsWithChildren<any> {
}
export function IdentityProvider (props: IdentityProviderProps) {
  // const { seed } = props;
  // const identity = new Identity(seed);
  // const commitment = identity.getCommitment().toString();
  const { data, isSuccess, signMessage, signMessageAsync } = useSignMessage({
    message: `${ZK_IDENTITY_SEED}_2`
  });
  let identity: Identity | null = null;

  useEffect(() => {
    async function _signId () {
      console.log('identity Context: ');
      let signature = await signMessageAsync({
        message:
        `${ZK_IDENTITY_SEED}_2`
      })
  
      console.log('signature: ', signature);
      identity = new Identity(signature);

    }
    // if (!identity)
    // createIdentity("1");
    _signId().catch(console.error);
  }, [data, isSuccess]);

  // useEffect(() => {
  //   if (!props.generateIdentity) {
  //     createIdentity();
  //   }
  // }, [props.generateIdentity]);

  return (
    <IdentityContext.Provider value={identity ? identity: null}>
      {props.children}
    </IdentityContext.Provider>
  );
}