import { v4 } from 'uuid';
import { Group } from "@semaphore-protocol/group";

// Todo: replace this with the correct IPFS gateway, I just used my pinata for testing
export async function uploadJSONToIPFS (val: string) {
  // upload to ipfs.io
  const res = await fetch('https://api.pinata.cloud/pinning/pinJSONToIPFS', {
    method: 'post',
    body : val,
    headers: {
      'Content-type': 'application/json',
      'Authorization': `Bearer ${process.env.REACT_APP_PINATA_JWT}`
    },
  });
  // const { IpfsHash } = await res.json()
  // const contentURI = `ipfs://${ IpfsHash }`;
  // console.log('contentURI: ', contentURI);
  // return contentURI;
  return res.json();
}


export async function generateZkCommunityMetaAndUploadToIPFS (communityId: string, name: string, description: string, coordinator: string, community: Group) {
  const communityMeta = {
    id: v4(),
    communityId,
    coordinator: coordinator,
    name: name,
    description: description,
    data: {
      members: community.members.map((m) => m.toString()),
    }
  };
  console.log('communityMeta: ', communityMeta);
  // upload to ipfs.io
  const res = await fetch('https://api.pinata.cloud/pinning/pinJSONToIPFS', {
    method: 'post',
    body : JSON.stringify(communityMeta),
    headers: {
      'Content-type': 'application/json',
      'Authorization': `Bearer ${process.env.REACT_APP_PINATA_JWT}`
    },
  });
  const { IpfsHash } = await res.json()
  const contentURI = `ipfs://${ IpfsHash }`;
  console.log('contentURI: ', contentURI);
  return contentURI;
}

export async function generatePollMetaAndUploadToIPFS (pollId: string, communityId: string, profileId: string, threshold: number, coordinator: string) {
  const pollMeta = {
    id: v4(),
    pollId: pollId,
    communityId: communityId,
    profileId: profileId,
    threshold: threshold,
    coordinator: coordinator,
    name: "Poll name goes here",
    description: "Poll description goes here",
    data: []
  };

  console.log('pollMeta: ', pollMeta);
  // upload to ipfs.io
  const res = await fetch('https://api.pinata.cloud/pinning/pinJSONToIPFS', {
    method: 'post',
    body : JSON.stringify(pollMeta),
    headers: {
      'Content-type': 'application/json',
      'Authorization': `Bearer ${process.env.REACT_APP_PINATA_JWT}`
    },
  });
  const { IpfsHash } = await res.json()
  const contentURI = `ipfs://${ IpfsHash }`;
  console.log('contentURI: ', contentURI);
  return contentURI;
}