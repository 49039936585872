// import { useEthersAdaptorFromProviderOrSigners } from "eth-hooks";
// import { useEthersAppContext } from "eth-hooks/context";
import { BigNumber } from "ethers";
import { useState, useEffect } from "react";
import { IERC721Enumerable__factory, ILensHub__factory } from "../contracts/types";
import { getLensProfile } from '../services/lensApi';
import config from "../constants";
import { useNetwork, useSigner } from "wagmi";
// ------------[TODO : ADD A CONFIG HERE]
const LENSHUB_ADDRESS = "0x60Ae865ee4C725cd04353b5AAb364553f56ceF82"
// -----------------------------------------
const useLensProfile = () => {
  // const ethersAppContext = useEthersAppContext();
  // const [adaptor] = useEthersAdaptorFromProviderOrSigners(ethersAppContext.provider);
  const {data: signer, isSuccess} = useSigner({
    onSuccess(data) {
      // console.log("Success", data);
    },
  });
  const { chain } = useNetwork();
  const chainId = chain?.id || "localhost";

  const [ profileId, setProfileId ] = useState("");
  const [ profile, setProfile ] = useState({} as Record<string, any>)
  
  useEffect(() => {
    async function getUserProfileId () {
      if (signer && isSuccess) {
        // console.log('lensHub address: ', config.contracts[String(chainId)].lensHub)
        const lensHub = IERC721Enumerable__factory.connect(
          config.contracts[String(chainId)].lensHub, 
          signer
        );
        const address = await signer.getAddress();
        const id = await lensHub.tokenOfOwnerByIndex(address, 0);
        // console.log('id: ', id); 
        // console.log('address: ', address);
        setProfileId(id.toHexString())

        const profile = await getLensProfile({ profileId: id.toHexString() })
        setProfile(profile.data.profile)
        // console.log('profile: ', profile);
      } else {
        console.log('not connected to metamask')
      }
    }
    getUserProfileId().catch(console.error)
  }, [signer, isSuccess])

  return {profileId, profile}
}

export default useLensProfile;