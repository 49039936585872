import React, { FC, useEffect, useState } from 'react'
import { getPollState } from '../../services/votingApi'
import { useClaimBadge } from '../../hooks/useClaimBadge'
import { BigNumber } from 'ethers'
import { Button, TextField } from '@mui/material';

export type Poll = {
  id: string
  communityId: string
  profileId: string
  coordinator: string
  threshold: number
  yesVotes: number
  noVotes?: number
  status?: number
  startedAt?: number
}

type PollState = {
  pollId: string
  communityId: string
  profileId: string
  yesVotes: number
  state: string
  threshold: number
}

type Props = {
  onVote: (communityId: string, pollId: string, nonce?: string) => void
  poll?: Poll
}

const PollPreview: FC<Props> = ({ onVote, poll }) => {
  const [pollState, setPollState] = useState<PollState>();
  const [ toggle, setToggle ] = useState(false);
  const { resp, triggerClaimBadge } = useClaimBadge();
  const nonce = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    async function _getPollState () {
      if (poll) {
        setPollState(undefined);
        const pollState = await getPollState(poll.id, poll.communityId);
        setPollState(pollState);
        console.log('refreshed poll state', poll);
      }
    }
    _getPollState().catch(console.error);
  }, [poll, toggle]);

  return (
    <div>
      <h1>Poll Preview</h1>
      <p>Poll ID: {poll?.id}</p>
      <p>Profile ID: {poll?.profileId}</p>
      <p>Community ID: {poll?.communityId}</p>
      <p>State: {pollState?.state ? ["Created","Ongoing","Ended","Claimed"][pollState?.state]: ''}</p>
      {/* <p>Coordinator: {poll?.coordinator}</p> */}
      <p>Threshold %: {poll?.threshold}%</p>
      <p>Absolute Threshold: {pollState?.threshold}</p>
      <p>Yes Votes: {pollState?.yesVotes}</p>
      <p><input type="text" name="nonce" ref={nonce}  placeholder='ZKIdentity pass'  /></p>
      <Button variant="contained" onClick={(ev) => {
        console.log("Vote yes", ev);
        onVote(poll?.communityId as string, poll?.id as string, nonce.current?.value);
      }}>Vote +1</Button>
      <Button variant="outlined" onClick={(ev) => {
        console.log("refresh state", ev);
        setToggle(!toggle);
      }}>refresh</Button>
      <Button disabled onClick={(ev) => {
        console.log("claim Badge", ev);
        triggerClaimBadge(poll?.id as string);
      }}>claimBadge</Button>
    </div>
  )
}

export default PollPreview;